import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";

const ManagePeople = () => {
  const [people, setPeople] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [nameChanges, setNameChanges] = useState({});
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchPeople = useCallback(async () => {
    try {
      const res = await axios.get(`${apiBaseUrl}/people`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      console.log("Fetched people:", res.data);

      if (Array.isArray(res.data.people)) {
        setPeople(res.data.people);
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (err) {
      console.error("Error fetching people:", err);
      setError(err.response?.data?.message || "Failed to fetch people");
    }
  }, [apiBaseUrl]);

  useEffect(() => {
    fetchPeople();
  }, [fetchPeople]);

  const handleRemovePerson = async (personId) => {
    setError("");
    setSuccess("");

    const confirmed = window.confirm(
      "Are you sure you want to remove this person?"
    );
    if (confirmed) {
      try {
        await axios.delete(`${apiBaseUrl}/people/${personId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        fetchPeople(); // Refetch people to ensure the UI is updated correctly
        setSuccess("Person removed successfully.");
      } catch (error) {
        setError(error.response?.data?.message || "Failed to remove person");
      }
    }
  };

  const handleUpdatePersonName = async (personId) => {
    setError("");
    setSuccess("");

    const newName = nameChanges[personId];
    try {
      const res = await axios.put(
        `${apiBaseUrl}/people/${personId}`,
        { name: newName },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setPeople(
        people.map((person) => (person._id === personId ? res.data : person))
      );
      setSuccess("Person updated successfully.");
    } catch (error) {
      setError(error.response?.data?.message || "Failed to update person name");
    }
  };

  const handleNameChange = (personId, newName) => {
    setNameChanges((prev) => ({
      ...prev,
      [personId]: newName,
    }));
  };

  return (
    <div className="min-h-screen bg-[#F8FEFF] flex justify-center px-4">
      <div className="w-full max-w-[1500px] mt-10 bg-white rounded-lg shadow-md p-6">
        <h2 className="text-xl font-semibold mb-4">Manage People</h2>
        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-500">{success}</p>}
        {people.length === 0 ? (
          <p>No people found.</p>
        ) : (
          <ul className="space-y-4">
            {people.map((person) => (
              <li key={person._id} className="flex items-center justify-between border p-4 rounded">
                <input
                  type="text"
                  value={nameChanges[person._id] || person.name}
                  onChange={(e) => handleNameChange(person._id, e.target.value)}
                  className="block w-full md:w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <div className="flex space-x-2">
                  <button
                    onClick={() => handleUpdatePersonName(person._id)}
                    className="px-4 py-2 border border-blue-500 text-blue-500 rounded hover:bg-blue-500 hover:text-white transition-colors"
                  >
                    Update
                  </button>
                  <button
                    onClick={() => handleRemovePerson(person._id)}
                    className="px-4 py-2 border border-red-500 text-red-500 rounded hover:bg-red-500 hover:text-white transition-colors"
                  >
                    Remove
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ManagePeople;
