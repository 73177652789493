import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ConfigureWaitingList from "./ConfigureWaitingList";

const CreateWaitingList = () => {
  const [organizationExists, setOrganizationExists] = useState(null);
  const [hasPermission, setHasPermission] = useState(false);
  const [contact, setContact] = useState(null);
  const [organizationId, setOrganizationId] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const checkOrganization = async () => {
      try {
        const res = await axios.get(`${apiBaseUrl}/organization/check`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (res.data.organizationExists) {
          setOrganizationExists(true);
          setHasPermission(res.data.hasPermission);
          setContact(res.data.contact);
          setOrganizationId(res.data.organization._id);
        } else {
          setOrganizationExists(false);
        }
      } catch (err) {
        console.error("Error checking organization:", err);
        setError(err.response?.data?.message || "Failed to check organization");
      }
    };
    checkOrganization();
  }, [apiBaseUrl]);

  const handleCreateOrganization = () => {
    navigate("/create-organization");
  };

  if (error) {
    return <p>{error}</p>;
  }

  if (organizationExists === null) {
    return <p>Loading...</p>;
  }

  if (organizationExists && hasPermission) {
    return <ConfigureWaitingList isEditMode={false} organizationId={organizationId} />;
  }

  if (organizationExists && !hasPermission) {
    return (
      <div className="min-h-screen bg-[#F8FEFF] flex items-center justify-center">
        <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-md">
          <p className="text-center mb-6">
            You need to contact the organization admin to create a waiting list.
          </p>
          <p className="text-center mb-2">Admin Name: {contact.name}</p>
          <p className="text-center mb-4">Admin Email: {contact.email}</p>
        </div>
      </div>
    );
  }

  if (!organizationExists) {
    return (
      <div className="min-h-screen bg-[#F8FEFF] flex items-center justify-center">
        <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-md">
          <p className="text-center mb-6">
            No organization found with your domain. Please create an organization.
          </p>
          <button
            onClick={handleCreateOrganization}
            className="w-full flex justify-center py-2 px-4 border border-blue-500 text-blue-500 rounded hover:bg-blue-500 hover:text-white transition-colors"
          >
            Create Organization
          </button>
        </div>
      </div>
    );
  }

  return null;
};

export default CreateWaitingList;
