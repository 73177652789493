import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom"; // Add useNavigate here

const ViewWaitingList = () => {
  const { waitingListId } = useParams();
  const navigate = useNavigate(); // Initialize useNavigate
  const [entries, setEntries] = useState([]);
  const [waitingListDetails, setWaitingListDetails] = useState(null);
  const [error, setError] = useState("");
  const [showNonActive, setShowNonActive] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showHistory, setShowHistory] = useState(false); // Toggle for activity history
  const [entryToCancel, setEntryToCancel] = useState(null);
  const [entryToOffer, setEntryToOffer] = useState(null);
  const [entryToView, setEntryToView] = useState(null);
  const [entryToReturn, setEntryToReturn] = useState(null);
  const [formData, setFormData] = useState({
    message: "",
    email: false,
    textMessage: false,
    timeToRespond: 1,
    timeUnit: "Hours"
  });
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchEntries = useCallback(async () => {
    try {
      const res = await axios.get(
        `${apiBaseUrl}/waiting-list-entry/waiting-list/${waitingListId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setEntries(res.data.entries);
    } catch (error) {
      console.error("Error fetching entries:", error);
      setError(error.response?.data?.message || "Failed to fetch entries");
    }
  }, [apiBaseUrl, waitingListId]);

  const fetchWaitingListDetails = useCallback(async () => {
    try {
      const res = await axios.get(
        `${apiBaseUrl}/waiting-list/${waitingListId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setWaitingListDetails(res.data.waitingList);
    } catch (error) {
      console.error("Error fetching waiting list details:", error);
      setError(error.response?.data?.message || "Failed to fetch waiting list details");
    }
  }, [apiBaseUrl, waitingListId]);

  useEffect(() => {
    fetchEntries();
    fetchWaitingListDetails();
  }, [fetchEntries, fetchWaitingListDetails]);

  useEffect(() => {
    const handleReturnToWaitingList = async () => {
      if (!entryToReturn) return; // Ensure entryToReturn is not null
      try {
        await axios.patch(
          `${apiBaseUrl}/waiting-list-entry/${waitingListId}/entry/${entryToReturn}/return-to-waiting-list`,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setEntryToReturn(null);
        fetchEntries();
      } catch (error) {
        console.error("Error returning entry to waiting list:", error);
        setError(error.response?.data?.message || "Failed to return entry to waiting list");
      }
    };

    handleReturnToWaitingList();
  }, [entryToReturn, apiBaseUrl, waitingListId, fetchEntries]);

  const handleMoveUp = async (waitingListEntryId) => {
    try {
      await axios.patch(
        `${apiBaseUrl}/waiting-list-entry/${waitingListId}/entry/${waitingListEntryId}/move-up`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      fetchEntries();
    } catch (error) {
      console.error("Error moving entry up:", error);
      setError(error.response?.data?.message || "Failed to move entry up");
    }
  };

  const handleMoveDown = async (waitingListEntryId) => {
    try {
      await axios.patch(
        `${apiBaseUrl}/waiting-list-entry/${waitingListId}/entry/${waitingListEntryId}/move-down`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      fetchEntries();
    } catch (error) {
      console.error("Error moving entry down:", error);
      setError(error.response?.data?.message || "Failed to move entry down");
    }
  };

  const handleCancel = async () => {
    try {
      await axios.patch(
        `${apiBaseUrl}/waiting-list-entry/${waitingListId}/entry/${entryToCancel}/cancel`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setShowCancelModal(false);
      setEntryToCancel(null);
      fetchEntries();
    } catch (error) {
      console.error("Error canceling entry:", error);
      setError(error.response?.data?.message || "Failed to cancel entry");
    }
  };

  const handleOfferPlace = async (e) => {
    e.preventDefault();
    if (!formData.email && !formData.textMessage) {
      alert("Please select at least one notification method (Email or Text Message).");
      return;
    }
    try {
      const { message, email, timeToRespond, timeUnit } = formData;
      const data = {
        message,
        email,
        timeToRespond,
        timeUnit,
        entryId: entryToOffer
      };

      await axios.post(`${apiBaseUrl}/waiting-list-entry/${waitingListId}/offer-place`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setShowOfferModal(false);
      setEntryToOffer(null);
      setFormData({ message: "", email: false, textMessage: false, timeToRespond: 1, timeUnit: "Hours" });
      fetchEntries();
    } catch (error) {
      console.error("Error offering place:", error);
      setError(error.response?.data?.message || "Failed to offer place");
    }
  };

  const isExpired = (entry) => {
    return entry.expiryDate && new Date(entry.expiryDate) < new Date();
  };

  const approvedEntries = entries.filter(entry => entry.status === "approved");
  const pendingEntries = entries.filter(entry => entry.status === "pending");
  const nonActiveEntries = entries.filter(entry => entry.status !== "approved" && entry.status !== "pending")
    .sort((a, b) => new Date(b.history[b.history.length - 1].changedAt) - new Date(a.history[a.history.length - 1].changedAt));

  const renderApprovedEntry = (entry) => (
    <li key={entry._id} className="border border-gray-300 p-4 mb-4 rounded flex flex-col md:flex-row items-center md:items-center">
      <div className="ml-4 flex-grow text-center md:text-left">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="text-md font-semibold">{entry.person.name}</h3>
            <p className="text-sm text-gray-600">Requested at: {new Date(entry.createdAt).toLocaleString()}</p>
            <p className="text-sm text-gray-600">Offer date: {new Date(entry.history.find(h => h.value === 'approved').changedAt).toLocaleString()}</p>
            <p className="text-sm text-gray-600">Expiry date: {new Date(entry.expiryDate).toLocaleString()}</p>
          </div>
          {isExpired(entry) && (
            <div className="bg-red-500 text-white text-sm font-semibold px-2 py-1 rounded-full">
              Place Expired
            </div>
          )}
        </div>
      </div>
      <div className="mt-4 md:mt-0 md:ml-12 text-center ">
        <button
          onClick={() => {
            setEntryToOffer(entry._id);
            setShowOfferModal(true);
          }}
          className="text-green-500 border border-green-500 rounded px-4 py-1 ml-0 md:ml-4 mb-2 hover:bg-green-500 hover:text-white transition-colors"
        >
          Resend Offer
        </button>
        <button
          onClick={() => {
            setEntryToReturn(entry._id);
          }}
          className="text-blue-500 border border-blue-500 rounded px-4 py-1 ml-0 md:ml-4 mb-2 hover:bg-blue-500 hover:text-white transition-colors"
        >
          Return to Waiting List
        </button>
        <button
          onClick={() => {
            setEntryToCancel(entry._id);
            setShowCancelModal(true);
          }}
          className="text-red-500 border border-red-500 rounded px-4 py-1 ml-0 md:ml-4 mb-2 hover:bg-red-500 hover:text-white transition-colors"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            setEntryToView(entry);
            setShowDetailsModal(true);
            setShowHistory(false); // Reset history view
          }}
          className="text-blue-500 border border-blue-500 rounded px-4 py-1 ml-0 md:ml-4 hover:bg-blue-500 hover:text-white transition-colors"
        >
          View Details
        </button>
      </div>
    </li>
  );

  const renderPendingEntry = (entry, index) => (
    <li key={entry._id} className="border border-gray-300 p-4 mb-4 rounded flex flex-col md:flex-row items-center md:items-center">
      <div className="ml-4 flex-grow text-center md:text-left">
        <h3 className="text-md font-semibold">{entry.person.name}</h3>
        <p className="text-sm text-gray-600">{new Date(entry.createdAt).toLocaleString()}</p>
        <p className="text-sm font-semibold mt-1">Position: {entry.position}</p>
      </div>
      <div className="mt-4 md:mt-0 md:ml-12 text-center">
        <button
          onClick={() => handleMoveUp(entry._id)}
          className={`text-blue-500 border border-blue-500 rounded px-4 py-1 ml-0 md:ml-4 mb-2 ${entry.position === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-500 hover:text-white transition-colors'}`}
          disabled={entry.position === 1}
        >
          Move Up
        </button>
        <button
          onClick={() => handleMoveDown(entry._id)}
          className={`text-blue-500 border border-blue-500 rounded px-4 py-1 ml-0 md:ml-4 mb-2 ${index === pendingEntries.length - 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-500 hover:text-white transition-colors'}`}
          disabled={index === pendingEntries.length - 1}
        >
          Move Down
        </button>
        <button
          onClick={() => {
            setEntryToOffer(entry._id);
            setShowOfferModal(true);
          }}
          className="text-green-500 border border-green-500 rounded px-4 py-1 ml-0 md:ml-4 mb-2 hover:bg-green-500 hover:text-white transition-colors"
        >
          Offer Place
        </button>
        <button
          onClick={() => {
            setEntryToCancel(entry._id);
            setShowCancelModal(true);
          }}
          className="text-red-500 border border-red-500 rounded px-4 py-1 ml-0 md:ml-4 hover:bg-red-500 hover:text-white transition-colors"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            setEntryToView(entry);
            setShowDetailsModal(true);
            setShowHistory(false); // Reset history view
          }}
          className="text-blue-500 border border-blue-500 rounded px-4 py-1 ml-0 md:ml-4 hover:bg-blue-500 hover:text-white transition-colors"
        >
          View Details
        </button>
      </div>
    </li>
  );

  const renderNonActiveEntry = (entry) => (
    <li key={entry._id} className="border border-gray-300 p-4 mb-4 rounded flex flex-col md:flex-row items-center md:items-center">
      <div className="ml-4 flex-grow text-center md:text-left">
        <h3 className="text-md font-semibold">{entry.person.name}</h3>
        <p className="text-sm text-gray-600">{new Date(entry.createdAt).toLocaleString()}</p>
        <p className="text-sm font-semibold mt-1">Last Change: {new Date(entry.history[entry.history.length - 1].changedAt).toLocaleString()}</p>
      </div>
      <div className="mt-4 md:mt-0 md:ml-12 text-center">
        <button
          onClick={() => {
            setEntryToView(entry);
            setShowDetailsModal(true);
            setShowHistory(false); // Reset history view
          }}
          className="text-blue-500 border border-blue-500 rounded px-4 py-1 ml-0 md:ml-4 hover:bg-blue-500 hover:text-white transition-colors"
        >
          View Details
        </button>
      </div>
    </li>
  );

  return (
    <div className="bg-[#F8FEFF] min-h-screen flex justify-center px-4">
      <div className="w-full max-w-[1600px] mt-10 flex flex-col lg:flex-row gap-8">
        <div className="bg-white rounded-lg shadow-md p-6 flex-grow">
          <h1 className="text-3xl font-semibold mb-4">Waiting List</h1>
          {error && <p className="text-red-500 mb-4">{error}</p>}

          {waitingListDetails && (
            <div className="mb-4">
              <button
                onClick={() => navigate(`/edit-waiting-list/${waitingListId}`)} // Navigate to edit page
                className="text-blue-500 border border-blue-500 rounded px-4 py-2 hover:bg-blue-500 hover:text-white transition-colors"
              >
                Edit Waiting List
              </button>
            </div>
          )}

          {approvedEntries.length > 0 && (
            <div className="mb-6">
              <h2 className="text-2xl font-semibold mb-4">Approved Requests</h2>
              <ul className="list-disc pl-5">
                {approvedEntries.map((entry) => renderApprovedEntry(entry))}
              </ul>
            </div>
          )}

          {pendingEntries.length > 0 && (
            <div className="mb-6">
              <h2 className="text-2xl font-semibold mb-4">Pending Requests</h2>
              <ul className="list-disc pl-5">
                {pendingEntries.map((entry, index) => renderPendingEntry(entry, index))}
              </ul>
            </div>
          )}

          {nonActiveEntries.length > 0 && (
            <div className="mb-6">
              <h2 className="text-2xl font-semibold mb-4">Non-Active Requests</h2>
              {showNonActive ? (
                <ul className="list-disc pl-5">
                  {nonActiveEntries.map((entry) => renderNonActiveEntry(entry))}
                </ul>
              ) : (
                <button
                  onClick={() => setShowNonActive(true)}
                  className="mt-4 px-4 py-2 bg-[#0D1B2A] text-white rounded hover:bg-[#09223c] transition-colors duration-200"
                >
                  View Non-Active Requests
                </button>
              )}
            </div>
          )}
        </div>
      </div>

      {showCancelModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h2 className="text-2xl font-semibold mb-4">Are you sure you want to cancel this request?</h2>
            <p className="mb-4">This action cannot be undone.</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowCancelModal(false)}
                className="px-4 py-2 border border-blue-500 text-blue-500 rounded hover:bg-blue-500 hover:text-white transition-colors"
              >
                Close
              </button>
              <button
                onClick={handleCancel}
                className="px-4 py-2 border border-red-500 text-red-500 rounded hover:bg-red-500 hover:text-white transition-colors"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {showOfferModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h2 className="text-2xl font-semibold mb-4">Offer Place</h2>
            <form onSubmit={handleOfferPlace} className="space-y-4">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Message
                </label>
                <textarea
                  value={formData.message}
                  onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                  className="mt-1 block w-full h-32 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  required
                ></textarea>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Notify By
                </label>
                <div className="flex items-center space-x-4">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={formData.email}
                      onChange={(e) => setFormData({ ...formData, email: e.target.checked })}
                      className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                    <label className="ml-2 block text-sm text-gray-900">Email</label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={formData.textMessage}
                      onChange={(e) => setFormData({ ...formData, textMessage: e.target.checked })}
                      className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      disabled={["Free", "Basic", "Club/Society/Charity"].includes(entryToOffer?.subscriptionTier)}
                    />
                    <label className="ml-2 block text-sm text-gray-900">Text Message</label>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Time to Respond
                </label>
                <div className="flex items-center">
                  <input
                    type="number"
                    value={formData.timeToRespond}
                    onChange={(e) => setFormData({ ...formData, timeToRespond: e.target.value })}
                    className="mt-1 block w-20 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    required
                  />
                  <select
                    value={formData.timeUnit}
                    onChange={(e) => setFormData({ ...formData, timeUnit: e.target.value })}
                    className="ml-2 mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  >
                    <option>Minutes</option>
                    <option>Hours</option>
                    <option>Days</option>
                  </select>
                </div>
              </div>
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={() => setShowOfferModal(false)}
                  className="px-4 py-2 border border-blue-500 text-blue-500 rounded hover:bg-blue-500 hover:text-white transition-colors"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 border border-green-500 text-green-500 rounded hover:bg-green-500 hover:text-white transition-colors"
                >
                  Offer Place
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

{showDetailsModal && entryToView && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
    <div className="bg-white rounded-lg p-6 w-full max-w-lg max-h-[80vh] overflow-y-auto">
      <h2 className="text-2xl font-semibold mb-4">Entry Details</h2>
      <p><strong>Name:</strong> {entryToView.person.name}</p>
      <p><strong>Status:</strong> {entryToView.status}</p>
      <p><strong>Requested at:</strong> {new Date(entryToView.createdAt).toLocaleString()}</p>
      {waitingListDetails?.coreFields?.dateOfBirth && entryToView.person.dateOfBirth && (
        <p><strong>Date of Birth:</strong> {new Date(entryToView.person.dateOfBirth).toLocaleDateString()}</p>
      )}
      {waitingListDetails?.coreFields?.address && entryToView.person.address && (
        <>
          {entryToView.person.address.street && <p><strong>Street:</strong> {entryToView.person.address.street}</p>}
          {entryToView.person.address.city && <p><strong>City:</strong> {entryToView.person.address.city}</p>}
          {entryToView.person.address.county && <p><strong>County:</strong> {entryToView.person.address.county}</p>}
          {entryToView.person.address.postcode && <p><strong>Postcode:</strong> {entryToView.person.address.postcode}</p>}
        </>
      )}
      {waitingListDetails?.coreFields?.gender && entryToView.person.gender && (
        <p><strong>Gender:</strong> {entryToView.person.gender}</p>
      )}
      {entryToView.customAnswers.length > 0 && (
        <>
          <h3 className="text-xl font-semibold mt-4">Custom Answers</h3>
          <ul className="list-disc pl-5">
            {entryToView.customAnswers.map((answer) => (
              <li key={answer.questionId}>
                <strong>{answer.questionId.questionText}:</strong> {answer.answer}
              </li>
            ))}
          </ul>
        </>
      )}
      {entryToView.expiryDate && (
        <p><strong>Expiry date:</strong> {new Date(entryToView.expiryDate).toLocaleString()}</p>
      )}
      <button
        onClick={() => setShowHistory(!showHistory)}
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors duration-200"
      >
        {showHistory ? "Hide" : "Show"} Activity History
      </button>
      {showHistory && (
        <div className="mt-4">
          <h3 className="text-xl font-semibold">Activity History</h3>
          <div className="flex flex-col space-y-2">
            {entryToView.history.sort((a, b) => new Date(b.changedAt) - new Date(a.changedAt)).map((item, index) => (
              <div key={index} className="flex text-sm text-gray-600">
                <div className="w-1/4">
                  <div>{new Date(item.changedAt).toLocaleDateString()}</div>
                  <div>{new Date(item.changedAt).toLocaleTimeString()}</div>
                </div>
                <div className="w-2/4 px-2">
                  {item.type === 'status' && `Status changed to ${item.value}`}
                  {item.type === 'position' && `Position changed to ${item.value}`}
                  {item.emailMessage && ` with message: "${item.emailMessage}"`}
                  {item.expiryTime && ` (Expires at: ${new Date(item.expiryTime).toLocaleString()})`}
                </div>
                <div className="w-1/4 text-right">
                  {item.changedBy && (
                    <span>
                      {item.changedBy.name || "Unknown User"}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="flex justify-end space-x-4 mt-4">
        <button
          onClick={() => setShowDetailsModal(false)}
          className="px-4 py-2 border border-blue-500 text-blue-500 rounded hover:bg-blue-500 hover:text-white transition-colors"
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}

    </div>
  );
};

export default ViewWaitingList;
