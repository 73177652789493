import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import OrganizationDetails from "./OrganizationDetails";
import ManageManagers from "./ManageManagers";
import UpdateSubscription from "./UpdateSubscription";

const ManageOrganization = () => {
  const [organizationId, setOrganizationId] = useState("");
  const [subscription, setSubscription] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const res = await axios.get(`${apiBaseUrl}/auth/check`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const userData = res.data.user;
          setOrganizationId(userData.organization);
          if (userData.role === "owner") {
            setIsAdmin(true);
          } else {
            navigate("/dashboard");
          }
        } catch (error) {
          console.error("Failed to fetch user data:", error);
          setError(error.response?.data?.message || "Failed to fetch user details");
        }
      }
    };

    fetchUserData();
  }, [apiBaseUrl, navigate]);

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const res = await axios.get(`${apiBaseUrl}/organization/${organizationId}/subscription`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setSubscription(res.data.subscriptionTier);
      } catch (error) {
        console.error("Failed to fetch subscription details:", error);
        setError(error.response?.data?.message || "Failed to fetch subscription details");
      }
    };

    if (organizationId) {
      fetchSubscription();
    }
  }, [apiBaseUrl, organizationId]);

  const handleSubscriptionChange = (newSubscription) => {
    setSubscription(newSubscription);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  if (!isAdmin) {
    return <p>Access Denied</p>;
  }

  return (
    <div className="min-h-screen bg-[#F8FEFF] flex justify-center px-4">
      <div className="w-full max-w-[1500px] mt-10 flex flex-col lg:flex-row gap-8">
        <div className="bg-white rounded-lg shadow-md p-6 w-full lg:w-[1000px]">
          {error && <p className="text-red-500 text-center mb-4">{error}</p>}
          <OrganizationDetails organizationId={organizationId} />
        </div>
        <div className="w-full lg:w-[500px] space-y-8">
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Current Subscription</h2>
            <p className="mb-4">Subscription Tier: {subscription}</p>
            <p className="mb-4">Email help@waitmate.app to change your subscription</p>
            {/* <button
              onClick={toggleModal}
              className="px-4 py-2 border border-blue-500 text-blue-500 rounded hover:bg-blue-500 hover:text-white transition-colors"
            >
              Change Subscription
            </button> */}
          </div>
          <div className="bg-white rounded-lg shadow-md p-6">
            <ManageManagers organizationId={organizationId} />
          </div>
        </div>
      </div>

      <UpdateSubscription
        showModal={showModal}
        toggleModal={toggleModal}
        organizationId={organizationId}
        currentSubscription={subscription}
        onSubscriptionChange={handleSubscriptionChange}
      />
    </div>
  );
};

export default ManageOrganization;
