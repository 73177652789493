import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import AddPerson from "./AddPerson";

const AddPersonPage = () => {
  const location = useLocation();
  const waitingListId = location.state?.waitingListId;
  const [people, setPeople] = useState([]);
  const [error, setError] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchPeople = async () => {
      try {
        const res = await axios.get(`${apiBaseUrl}/people`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setPeople(res.data.people || []);
      } catch (error) {
        setError(error.response?.data?.message || "Failed to fetch people");
      }
    };

    const fetchUserRole = async () => {
      try {
        const res = await axios.get(`${apiBaseUrl}/auth/check`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const userData = res.data.user; // Extract user data
        setIsAdmin(userData.role === "owner" || userData.role === "manager");
        setUserEmail(userData.email); // Set the logged-in user's email
      } catch (error) {
        setError(error.response?.data?.message || "Failed to fetch user role");
      }
    };

    fetchPeople();
    fetchUserRole();
  }, [apiBaseUrl]);

  const handlePersonAdded = (newPerson) => {
    setPeople((prevPeople) => [...prevPeople, newPerson]);
  };

  if (!waitingListId) {
    return <p>Error: Waiting list ID not found.</p>;
  }

  return (
    <div>
      <h2>Add Person to Waiting List</h2>
      {error && <p>{error}</p>}
      <AddPerson
        waitingListId={waitingListId}
        onPersonAdded={handlePersonAdded} // Pass the handler
        people={people}
        isAdmin={isAdmin}
        userEmail={userEmail} // Pass the logged-in user's email to AddPerson
      />
    </div>
  );
};

export default AddPersonPage;
