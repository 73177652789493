import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import logo from '../assets/goqueuelogo.png';

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleForgotPassword = async () => {
    try {
      const res = await axios.post(`${apiBaseUrl}/auth/forgot-password`, { email });
      setMessage(res.data.message);
    } catch (error) {
      setError(
        error.response?.data?.message || "Failed to send password reset email"
      );
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Top Bar */}
      <div className="w-full bg-[#0D1B2A] h-16 flex items-center px-5 gap-10">
        <img src={logo} alt="WaitMate Logo" className="h-12" />
      </div>
      
      <div className="flex-grow flex items-center justify-center bg-[#F8FEFF]">
        <div className="w-full max-w-md bg-white rounded-lg p-8 shadow-md">
          <h2 className="text-3xl font-semibold text-center mb-4">Forgot Password</h2>
          <p className="text-center mb-6">Enter the registered e-mail. We will send a verification code to reset your password.</p>
          {message && <p className="text-green-500 text-center mb-4">{message}</p>}
          {error && <p className="text-red-500 text-center mb-4">{error}</p>}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Email address</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <button
            onClick={handleForgotPassword}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Send Reset Email
          </button>
          <p className="mt-6 text-center text-sm text-gray-600">
            Back to <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">Sign In</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
