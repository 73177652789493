import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import loadingImage from '../assets/loading.svg'; // Adjust the path as needed

const AddPerson = ({ waitingListId, onPersonAdded, people = [], isAdmin, userEmail: loggedInUserEmail, onClose }) => {
  const [userEmail, setUserEmail] = useState(loggedInUserEmail || "");
  const [selectedPerson, setSelectedPerson] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [coreFields, setCoreFields] = useState({});
  const [customQuestions, setCustomQuestions] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    dateOfBirth: "",
    address: {
      street: "",
      city: "",
      county: "",
      postcode: ""
    },
    gender: "",
    customAnswers: {}
  });
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if (!loggedInUserEmail) {
      setUserEmail("");
    }

    const fetchWaitingListDetails = async () => {
      try {
        const res = await axios.get(`${apiBaseUrl}/waiting-list/${waitingListId}`);
        setCoreFields(res.data.waitingList.coreFields);

        const organizationId = res.data.waitingList.organization._id; // Ensure this is correctly extracted
        console.log('Organization ID:', organizationId);

        // Fetch full details of custom questions
        const questions = await Promise.all(res.data.waitingList.questions.map(async questionId => {
          const questionRes = await axios.get(`${apiBaseUrl}/organization/${organizationId}/custom-questions/${questionId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          });
          return questionRes.data;
        }));
        setCustomQuestions(questions);
        console.log('Custom Questions:', questions);
      } catch (err) {
        console.error("Error fetching waiting list details:", err);
        setError(err.response?.data?.message || "Failed to fetch waiting list details");
      }
    };

    fetchWaitingListDetails();
  }, [waitingListId, apiBaseUrl, loggedInUserEmail]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      address: {
        ...formData.address,
        [name]: value
      }
    });
  };

  const handleCustomChange = (e, questionId) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      customAnswers: {
        ...formData.customAnswers,
        [questionId]: value
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Create request body excluding empty fields
    const requestBody = {
      ...formData,
      waitingListId,
      userEmail
    };

    if (!coreFields.dateOfBirth) {
      delete requestBody.dateOfBirth;
    }
    if (!coreFields.address) {
      delete requestBody.address;
    }
    if (!coreFields.gender) {
      delete requestBody.gender;
    }

    console.log("Request body:", requestBody);

    try {
      const res = await axios.post(`${apiBaseUrl}/waiting-list-entry/join`, requestBody, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (res.data.message === "Person is already on the waiting list") {
        setMessage(res.data.message);
      } else {
        const newPerson = res.data.entry.person;
        setMessage("Person added successfully");
        onPersonAdded(newPerson);
        navigate("/dashboard");
      }

      setFormData({
        name: "",
        dateOfBirth: "",
        address: {
          street: "",
          city: "",
          county: "",
          postcode: ""
        },
        gender: "",
        customAnswers: {}
      });
      setUserEmail(loggedInUserEmail || "");
      setSelectedPerson("");
    } catch (error) {
      console.error("Error adding person:", error);
      if (error.response && error.response.status === 403) {
        setError("The organization has reached the limits of their subscription. Please contact the organization directly.");
      } else {
        setError("Failed to add person");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChange = (e) => {
    const selectedPersonId = e.target.value;
    setSelectedPerson(selectedPersonId);
    if (selectedPersonId !== "new") {
      const selectedPerson = people.find((person) => person._id === selectedPersonId);
      if (selectedPerson) {
        console.log("Selected person:", selectedPerson);
        setFormData({
          ...formData,
          name: selectedPerson.name,
          dateOfBirth: selectedPerson.dateOfBirth ? new Date(selectedPerson.dateOfBirth).toISOString().split('T')[0] : "",
          address: selectedPerson.address || {
            street: "",
            city: "",
            county: "",
            postcode: ""
          },
          gender: selectedPerson.gender || "",
          customAnswers: {} // Reset custom answers to ensure they are re-filled
        });
        setUserEmail(selectedPerson.userId?.email || loggedInUserEmail); // Ensure email is set from userId or logged in user
        console.log("Selected person email:", selectedPerson.userId?.email);
        console.log("userEmail:", userEmail);
      }
    } else {
      setFormData({
        name: "",
        dateOfBirth: "",
        address: {
          street: "",
          city: "",
          county: "",
          postcode: ""
        },
        gender: "",
        customAnswers: {}
      });
      setUserEmail(loggedInUserEmail || "");
    }
  };

  return (
    <div className="p-4">
      {loading ? (
        <div>
          <img src={loadingImage} alt="Loading" />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <h2 className="text-xl font-semibold mb-4">Join Waiting List</h2>
          <select
            value={selectedPerson}
            onChange={handleSelectChange}
            required
            className="block w-full px-3 py-2 mb-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="" disabled>
              Select a person
            </option>
            {people.map((person) => (
              <option key={person._id} value={person._id}>
                {person.name}
              </option>
            ))}
            <option value="new">Add a new person</option>
          </select>
          {(selectedPerson !== "" || selectedPerson === "new") && (
            <>
              <div className="mb-4">
                <input
                  type="text"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  name="name"
                  required
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              {coreFields.dateOfBirth && (
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Date of Birth</label>
                  <input
                    type="date"
                    name="dateOfBirth"
                    value={formData.dateOfBirth}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    required
                  />
                </div>
              )}
              {coreFields.address && (
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Address</label>
                  <input
                    type="text"
                    name="street"
                    placeholder="Street"
                    value={formData.address.street}
                    onChange={handleAddressChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    required
                  />
                  <input
                    type="text"
                    name="city"
                    placeholder="City"
                    value={formData.address.city}
                    onChange={handleAddressChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    required
                  />
                  <input
                    type="text"
                    name="county"
                    placeholder="County"
                    value={formData.address.county}
                    onChange={handleAddressChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    required
                  />
                  <input
                    type="text"
                    name="postcode"
                    placeholder="Postcode"
                    value={formData.address.postcode}
                    onChange={handleAddressChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    required
                  />
                </div>
              )}
              {coreFields.gender && (
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Gender</label>
                  <select
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    required
                  >
                    <option value="">Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              )}
              {customQuestions.map((question) => (
                <div key={question._id} className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">{question.questionText}</label>
                  {question.dataType === "text" && (
                    <input
                      type="text"
                      value={formData.customAnswers[question._id] || ""}
                      onChange={(e) => handleCustomChange(e, question._id)}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                      required={question.required}
                    />
                  )}
                  {question.dataType === "number" && (
                    <input
                      type="number"
                      value={formData.customAnswers[question._id] || ""}
                      onChange={(e) => handleCustomChange(e, question._id)}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                      required={question.required}
                    />
                  )}
                  {question.dataType === "boolean" && (
                    <select
                      value={formData.customAnswers[question._id] || ""}
                      onChange={(e) => handleCustomChange(e, question._id)}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                      required={question.required}
                    >
                      <option value="">Select</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  )}
                  {question.dataType === "date" && (
                    <input
                      type="date"
                      value={formData.customAnswers[question._id] || ""}
                      onChange={(e) => handleCustomChange(e, question._id)}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                      required={question.required}
                    />
                  )}
                  {question.dataType === "choice" && (
                    <select
                      value={formData.customAnswers[question._id] || ""}
                      onChange={(e) => handleCustomChange(e, question._id)}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                      required={question.required}
                    >
                      <option value="">Select</option>
                      {question.choices.map((choice, index) => (
                        <option key={`${question._id}-${index}`} value={choice}>{choice}</option>
                      ))}
                    </select>
                  )}
                </div>
              ))}
              {isAdmin && (
                <div className="mb-4">
                  <input
                    type="email"
                    placeholder="User's Email"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                    required
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              )}
              <div className="flex justify-between mt-4">
                <button
                  type="submit"
                  className="px-4 py-2 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Add to list
                </button>
              </div>
            </>
          )}
          {message && <p className="text-green-500 mt-4">{message}</p>}
          {error && <p className="text-red-500 mt-4">{error}</p>}
        </form>
      )}
    </div>
  );
};

export default AddPerson;
