import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import defaultBackground from '../assets/goqueueback.jpg';
import defaultLogo from '../assets/orgicon.jpg';
import AddPerson from "./AddPerson";

const WaitingListDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [waitingList, setWaitingList] = useState(null);
  const [organization, setOrganization] = useState(null);
  // const [entries, setEntries] = useState([]);
  const [isManagerOrOwner, setIsManagerOrOwner] = useState(false); // New state for manager or owner
  const [error, setError] = useState("");
  const [showAddPersonModal, setShowAddPersonModal] = useState(false);
  const [people, setPeople] = useState([]);
  const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail') || "");
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchWaitingListDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const authHeaders = token ? { headers: { Authorization: `Bearer ${token}` } } : {};

        const res = await axios.get(`${apiBaseUrl}/waiting-list/${id}`, authHeaders);
        setWaitingList(res.data.waitingList);

        if (token) {
          // const entriesRes = await axios.get(`${apiBaseUrl}/waiting-list-entry/waiting-list/${id}`, authHeaders);
          // setEntries(entriesRes.data.entries);
          
          const orgId = res.data.waitingList.organization._id || res.data.waitingList.organization;
          const orgRes = await axios.get(`${apiBaseUrl}/organization/${orgId}`, authHeaders);
          setOrganization(orgRes.data.organization);

          const peopleRes = await axios.get(`${apiBaseUrl}/people`, authHeaders);
          setPeople(peopleRes.data.people);

          const userRes = await axios.get(`${apiBaseUrl}/auth/check`, authHeaders);
          const user = userRes.data.user;

          if (res.data.waitingList.managers.includes(user._id) || orgRes.data.organization.owner === user._id) {
            setIsManagerOrOwner(true);
          }

          // Ensure the user email is stored in localStorage
          if (!localStorage.getItem('userEmail')) {
            localStorage.setItem('userEmail', user.email);
            setUserEmail(user.email);
          }
        } else {
          const orgId = res.data.waitingList.organization._id || res.data.waitingList.organization;
          const orgRes = await axios.get(`${apiBaseUrl}/organization/${orgId}`);
          setOrganization(orgRes.data.organization);
        }
      } catch (err) {
        setError(
          err.response?.data?.message || "Failed to fetch waiting list details"
        );
      }
    };

    fetchWaitingListDetails();
  }, [id, apiBaseUrl, navigate]);

  const handleAddPerson = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/register", { state: { from: `/waiting-list/${id}` } });
    } else {
      setShowAddPersonModal(true);
    }
  };

  const handlePersonAdded = () => {
    setShowAddPersonModal(false);
    // Refresh waiting list details if necessary
  };

  if (error) return <p>{error}</p>;

  if (!waitingList || !organization) return <p>Loading...</p>;

  return (
    <div className="bg-[#F8FEFF] min-h-screen flex justify-center px-4 py-8">
      <div className="w-full max-w-[1300px] flex flex-col lg:flex-row gap-8">
        <div className="lg:w-[400px] bg-white rounded-lg shadow-md p-6 flex-shrink-0">
          <div className="flex items-center mb-4">
            <img
              src={waitingList.iconUrl || organization.logo || defaultLogo}
              alt={`${waitingList.name} logo`}
              className="w-24 h-24 rounded-lg shadow-md object-contain"
            />
            <div className="ml-4">
              <h2 className="text-xl font-semibold">{organization.name}</h2>
              <p className="text-sm text-gray-600">{waitingList.name}</p>
            </div>
          </div>
          <hr className="my-4" />
          {/* {waitingList.allowViewQueueLength && (
            <div className="text-center mb-4">
              <span className=" text-gray-600">Total Joined:</span>
              <span className=" font-semibold text-gray-900 ml-2">{entries.length}</span>
            </div>
          )} */}
          <div className="flex justify-center">
            {isManagerOrOwner ? (
              <Link to={`/view-waiting-list/${waitingList._id}`} className="px-4 py-2 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700">
                Manage this list
              </Link>
            ) : (
              <button
                onClick={handleAddPerson}
                className="px-4 py-2 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700"
              >
                Join Waiting List
              </button>
            )}
          </div>
          <div className="mt-6 text-sm font-semibold text-center">
            <p className="mb-2">Have any questions? Contact us</p>
            <div className="text-gray-600 font-normal">
              {organization.email && (
                <p>
                  Email: <a href={`mailto:${organization.email}`} className="text-indigo-600">{organization.email}</a>
                </p>
              )}
              {organization.contactNumber && (
                <p>
                  Contact Number: {organization.contactNumber}
                </p>
              )}
              {organization.website && (
                <p>
                  Website: <a href={organization.website} target="_blank" rel="noopener noreferrer" className="text-indigo-600">{organization.website}</a>
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="flex-grow flex flex-col gap-8">
          <div className="bg-white rounded-lg shadow-md p-6">
            <nav className="text-sm mb-4">
              <Link to={`/organization/${organization._id}`} className="text-indigo-600">{organization.name}</Link> &gt; <span>{waitingList.name}</span>
            </nav>
            <div
              className="w-full h-48 bg-cover bg-center rounded-lg mb-6"
              style={{ backgroundImage: `url(${waitingList.bannerImageUrl || organization.backgroundImage || defaultBackground})` }}
            ></div>
            <h1 className="text-2xl font-semibold mb-6">{waitingList.name}</h1>
            <p className="text-sm text-gray-900 mb-6">{waitingList.description}</p>
            {/* <h2 className="text-lg font-semibold mb-4">List Criteria</h2>
            <div className="flex flex-wrap gap-2 mb-6">
              {["Sample Criteria 1", "Sample Criteria 2", "Sample Criteria 3", "Sample Criteria 4"].map((criteria, index) => (
                <span key={index} className="bg-indigo-100 text-indigo-700 px-2 py-1 rounded-full text-xs">{criteria}</span>
              ))}
            </div>
            <h2 className="text-lg font-semibold mb-4">List Order</h2>
            <ol className="list-decimal list-inside mb-6">
              {[
                { sortOrder: 1, sortCriteria: "Priority ticket" },
                { sortOrder: 2, sortCriteria: "Sibling at organization" },
                { sortOrder: 3, sortCriteria: "Distance from organization" },
                { sortOrder: 4, sortCriteria: "First come first served" },
              ].map((order, index) => (
                <li key={index} className="text-sm text-gray-900">{order.sortCriteria}</li>
              ))}
            </ol> */}
          </div>
        </div>
      </div>
      {showAddPersonModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <AddPerson
              waitingListId={id}
              onPersonAdded={handlePersonAdded}
              people={people}
              isAdmin={true}
              userEmail={userEmail}
            />
            <button
              onClick={() => setShowAddPersonModal(false)}
              className="mt-4 px-4 py-2 border border-blue-500 text-blue-500 rounded hover:bg-blue-500 hover:text-white transition-colors"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WaitingListDetails;
