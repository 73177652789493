import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
// import AuthContext from "../context/AuthContext";
import backgroundImage from './Auth/goqueueback.png';

const EmailVerification = () => {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState("Verifying...");
  // const { resendVerificationEmail } = useContext(AuthContext);
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const token = searchParams.get("token");

    const verifyEmail = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/auth/verify-email?token=${token}`);
        setMessage(response.data.message);
      } catch (error) {
        setMessage(
          error.response?.data?.message || "An error occurred during verification."
        );
      }
    };

    if (token) {
      verifyEmail();
    } else {
      setMessage("Invalid verification link.");
    }
  }, [searchParams, apiBaseUrl]);

  // const handleResend = async () => {
  //   try {
  //     const email = searchParams.get("email"); // Assuming email is passed as a query param
  //     const msg = await resendVerificationEmail(email);
  //     setMessage(msg);
  //   } catch (error) {
  //     setMessage("Error resending verification email");
  //     console.error("Error resending verification email:", error);
  //   }
  // };

  const handleContinue = () => {
    navigate("/"); // Navigate to the home page or any specific page
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      {/* Background Image for Desktop/Tablet */}
      <div
        className="hidden md:block md:w-2/3 min-h-screen bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      ></div>

      {/* Background Color for Desktop/Tablet and Mobile view */}
      <div className="md:w-1/3 w-full flex items-center justify-center bg-[#F8FEFF] p-6">
        <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-md md:max-w-none md:w-96">
          <h2 className="text-3xl font-semibold text-center mb-4">Email Verification</h2>
          <p className="text-center mb-6">{message}</p>
          {(message === "Email verified successfully" || message === "Email is already verified") && (
            <button
              onClick={handleContinue}
              className="w-full flex justify-center py-2 px-4 mb-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Continue
            </button>
          )}
          {(message === "Invalid verification link." || message === "An error occurred during verification."  || message === "Server error") && (
            <>
              {/* <button
                onClick={handleResend}
                className="w-full flex justify-center py-2 px-4 mb-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Resend Verification Email
              </button> */}
              <p className="mt-6 text-center text-gray-600">
                Need help? <Link to="/contact" className="font-medium text-indigo-600 hover:text-indigo-500">Contact Support</Link>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
