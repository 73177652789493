import React, { useState, useContext } from "react";
import AuthContext from "../context/AuthContext";
import AddPerson from "./AddPerson";

const JoinWaitingListModal = ({ onClose, waitingListId }) => {
  const { login, register } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [showAddPerson, setShowAddPerson] = useState(false);
  const [showRegistrationFields, setShowRegistrationFields] = useState(false);
  const [error, setError] = useState(null);

  const handleEmailCheck = async () => {
    try {
      const res = await fetch(`/api/auth/check-email`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });
      const data = await res.json();
      if (data.exists) {
        setIsExistingUser(true);
        setShowRegistrationFields(false);
      } else {
        setIsExistingUser(false);
        setShowRegistrationFields(true);
      }
    } catch (error) {
      setError("Failed to check email.");
    }
  };

  const handleLogin = async () => {
    try {
      await login(email, password);
      setShowAddPerson(true);
    } catch (error) {
      setError("Login failed. Please check your credentials.");
    }
  };

  const handleRegister = async () => {
    try {
      // Generate a random password
      const randomPassword = Math.random().toString(36).slice(-8);
      await register(name, email, randomPassword, phoneNumber);
      setShowAddPerson(true);
    } catch (error) {
      setError("Registration failed. Please try again.");
    }
  };

  if (showAddPerson) {
    return (
      <AddPerson
        waitingListId={waitingListId}
        userEmail={email}
        onPersonAdded={() => onClose()}
        onClose={onClose}
      />
    );
  }

  return (
    <div className="modal">
      <h2>Join Waiting List</h2>
      {error && <p className="error">{error}</p>}
      <div>
        <label>Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={handleEmailCheck}
          required
        />
      </div>

      {isExistingUser ? (
        <div>
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button onClick={handleLogin}>Login</button>
        </div>
      ) : showRegistrationFields ? (
        <div>
          <label>Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <label>Phone Number</label>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
          <button onClick={handleRegister}>Next</button>
        </div>
      ) : null}

      <button onClick={onClose}>Close</button>
    </div>
  );
};

export default JoinWaitingListModal;
