import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const OrganizationVanity = () => {
  const { vanityUrl } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/organization/vanity/${vanityUrl}`);
        const organization = response.data;
        navigate(`/organization/${organization._id}`);
      } catch (error) {
        setError(error.response?.data?.message || "Error fetching organization");
      }
    };

    fetchOrganization();
  }, [vanityUrl, navigate]);

  if (error) {
    return <div>{error}</div>;
  }

  return <div>Loading...</div>; // You can replace this with a loading spinner if desired
};

export default OrganizationVanity;
