import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import orgIcon from '../../assets/orgicon.jpg';
import personBrowsingImg from '../../assets/img-personbrowsing.jpg';

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [waitingLists, setWaitingLists] = useState([]);
  const [ownedWaitingLists, setOwnedWaitingLists] = useState([]);
  const [sortOption, setSortOption] = useState("Newest");
  const [showArchived, setShowArchived] = useState(false);
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchUserDetails = useCallback(async () => {
    try {
      const res = await axios.get(`${apiBaseUrl}/auth/check`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const userData = res.data.user;
      setUser(userData);
    } catch (error) {
      console.error('Error fetching user details:', error);
      setError(error.response?.data?.message || "Failed to fetch user details");
    }
  }, [apiBaseUrl]);

  const fetchWaitingLists = useCallback(async () => {
    try {
      const res = await axios.get(
        `${apiBaseUrl}/waiting-list-entry/user-people-waiting-lists`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log('Fetched waiting lists:', res.data);
      setWaitingLists(res.data.waitingLists || []);
    } catch (error) {
      console.error('Error fetching waiting lists:', error);
      setError(
        error.response?.data?.message || "Failed to fetch waiting lists"
      );
    }
  }, [apiBaseUrl]);

  const fetchOwnedWaitingLists = useCallback(async () => {
    try {
      const res = await axios.get(
        `${apiBaseUrl}/waiting-list/owned`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const ownedLists = res.data.waitingLists || [];
      console.log('Fetched owned waiting lists:', ownedLists);
      setOwnedWaitingLists(ownedLists);

      // Fetch entries for each waiting list to compute counts
      const updatedLists = await Promise.all(ownedLists.map(async list => {
        const entriesRes = await axios.get(
          `${apiBaseUrl}/waiting-list-entry/waiting-list/${list._id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        return { ...list, entries: entriesRes.data.entries };
      }));
      setOwnedWaitingLists(updatedLists);
    } catch (error) {
      console.error('Error fetching owned waiting lists:', error);
      setError(
        error.response?.data?.message || "Failed to fetch owned waiting lists"
      );
    }
  }, [apiBaseUrl]);

  useEffect(() => {
    fetchUserDetails();
    fetchWaitingLists();
    fetchOwnedWaitingLists();
  }, [fetchUserDetails, fetchWaitingLists, fetchOwnedWaitingLists]);

  const handleRemovePerson = async () => {
    const { waitingList, person } = selectedEntry;
    try {
      await axios.delete(
        `${apiBaseUrl}/waiting-list-entry/${waitingList._id}/person/${person._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setShowLeaveModal(false);
      await fetchWaitingLists();
    } catch (error) {
      setError(error.response?.data?.message || "Failed to remove person");
    }
  };
  

  const handleArchivePerson = async () => {
    const { waitingList, person } = selectedEntry;
    try {
      await axios.patch(
        `${apiBaseUrl}/waiting-list-entry/${waitingList._id}/person/${person._id}/archive`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setShowArchiveModal(false);
      await fetchWaitingLists();
    } catch (error) {
      setError(error.response?.data?.message || "Failed to archive person");
    }
  };
  

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const sortWaitingLists = (lists) => {
    switch (sortOption) {
      case "Newest":
        return lists.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      case "Oldest":
        return lists.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      case "Alphabetically":
        return lists.sort((a, b) => a.waitingList.name.localeCompare(b.waitingList.name));
      default:
        return lists;
    }
  };

  if (error) {
    return <p>{error}</p>;
  }

  if (!user) {
    return <p>Loading...</p>;
  }

  const sortedWaitingLists = sortWaitingLists([...waitingLists]);
  const pendingRequests = sortedWaitingLists.filter(entry => entry.status === "pending");
  const approvedRequests = sortedWaitingLists.filter(entry => entry.status === "approved");
  const cancelledRequests = sortedWaitingLists.filter(entry => entry.status === "cancelled");
  const archivedRequests = sortedWaitingLists.filter(entry => entry.status === "archived");

  const renderWaitingListCard = (entry) => (
    <div key={entry._id} className="border border-gray-300 p-4 mb-4 rounded flex flex-col md:flex-row items-center md:items-center">
      <div className="flex-shrink-0 mb-4 md:mb-0">
        <img
          src={entry.waitingList.organization.logo || orgIcon}
          alt="Organization Logo"
          className="w-16 h-16 rounded object-contain"
        />
      </div>
      <div className="flex-grow text-center md:text-left md:ml-4">
        <h3 className="text-md font-semibold">{entry.waitingList.name}</h3>
        <p className="text-sm text-gray-600">{entry.waitingList.description}</p>
        <p className="text-sm font-semibold mt-1">{entry.person.name}</p>
      </div>
      {entry.status === "pending" && entry.waitingList.allowViewPosition && (
        <div className="mt-4 md:mt-0 md:ml-12 text-center">
          <p className="font-semibold">Position</p>
          <div className="bg-[#0D1B2A] text-white font-bold rounded p-2">
            {entry.waitingList.allowViewQueueLength
              ? `${entry.position} of ${entry.pendingCount}`
              : entry.position}
          </div>
        </div>
      )}
      <div className="mt-4 md:mt-0 md:ml-12 text-center">
        <p className="font-semibold">Requested:</p>
        <p>{new Date(entry.createdAt).toLocaleString()}</p>
      </div>
      <div className="mt-4 md:mt-0 md:ml-12 text-center">
        <p className="font-semibold">Status:</p>
        <p>{entry.status.charAt(0).toUpperCase() + entry.status.slice(1)}</p>
      </div>
      <div className="mt-4 md:mt-0 md:ml-12 text-center">
        {entry.status === "pending" && (
          <button
            onClick={() => {
              setSelectedEntry(entry);
              setShowLeaveModal(true);
            }}
            className="text-red-500 border border-red-500 rounded px-4 py-1 hover:bg-red-500 hover:text-white transition-colors"
          >
            Leave waiting list
          </button>
        )}
        {(entry.status === "cancelled" || entry.status === "approved") && (
          <button
            onClick={() => {
              setSelectedEntry(entry);
              setShowArchiveModal(true);
            }}
            className="text-blue-500 border border-blue-500 rounded px-4 py-1 ml-0 md:ml-4 hover:bg-blue-500 hover:text-white transition-colors"
          >
            Archive request
          </button>
        )}
      </div>
    </div>
  );

  const renderOwnedWaitingListCard = (list) => {
    const approvedCount = (list.entries || []).filter(entry => entry.status === "approved").length;
    const pendingCount = (list.entries || []).filter(entry => entry.status === "pending").length;
  
    return (
      <div key={list._id} className="border border-gray-300 p-4 mb-4 rounded flex flex-col md:flex-row items-center md:items-center">
        <div className="flex-shrink-0 mb-4 md:mb-0">
          <img
            src={list.iconUrl || orgIcon}
            alt="Organization Logo"
            className="w-16 h-16 rounded object-contain"
          />
        </div>
        <div className="flex-grow text-center md:text-left md:ml-4">
          <h3 className="text-md font-semibold">{list.name}</h3>
          <p className="text-sm text-gray-600">{list.description}</p>
        </div>
          <div className="mt-4 md:mt-0 md:ml-12 text-center">
            <p className="text-sm font-semibold mb-1">Approved</p>
            <div className="bg-[#0D1B2A] text-white font-bold rounded p-2">
              {approvedCount}
            </div>
          </div>
          <div className="mt-4 md:mt-0 md:ml-12 text-center">
            <p className="text-sm font-semibold mb-1">Pending</p>
            <div className="bg-[#0D1B2A] text-white font-bold rounded p-2">
              {pendingCount}
            </div>
          </div>
          <div className="mt-4 md:mt-0 md:ml-12 text-center">
          <button className="text-blue-500 border border-blue-500 rounded px-4 py-1 ml-0 md:ml-4 hover:bg-blue-500 hover:text-white transition-colors">
            <Link to={`/view-waiting-list/${list._id}`}>Manage</Link>
          </button>
        </div>
      </div>
    );
  };
  
  const noPendingOrApprovedRequests = approvedRequests.length === 0 && pendingRequests.length === 0;
  const isManagerOrOwner = ownedWaitingLists.length > 0;

  return (
    <div className="bg-[#F8FEFF] min-h-screen flex justify-center px-4">
      <div className="w-full max-w-[1600px] mt-10 flex flex-col lg:flex-row gap-8">
        <div className="hidden lg:block bg-white rounded-lg shadow-md p-6 w-full lg:w-[350px]">
          {user && (
            <>
              <h2 className="text-xl font-semibold">{user.name}</h2>
              <p>{user.email}</p>
              <p>People managed: {user.people?.length || 0}</p>
              <p>Active waiting list requests: {pendingRequests.length}</p>
              <p>Approved waiting list requests: {approvedRequests.length}</p>
            </>
          )}
        </div>
        <div className="bg-white rounded-lg shadow-md p-6 flex-grow">
          <h1 className="text-3xl font-semibold mb-4">Welcome, {user?.name}</h1>
          <p className="mb-6">Manage your waiting list requests here</p>

          {ownedWaitingLists.length > 0 && (
            <div className="mb-12">
              <h2 className="text-xl font-semibold mb-4">Waiting lists you manage</h2>
              {ownedWaitingLists.map(list => renderOwnedWaitingListCard(list))}
            </div>
          )}

          {!showArchived ? (
            <>
              {approvedRequests.length > 0 && (
                <>
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-semibold">Your approved waiting list requests ({approvedRequests.length})</h2>
                    {approvedRequests.length > 0 && (
                      <select value={sortOption} onChange={handleSortChange} className="border border-[#DEDEDE] rounded px-2 py-1">
                        <option value="Newest">Newest</option>
                        <option value="Oldest">Oldest</option>
                        <option value="Alphabetically">Alphabetically</option>
                      </select>
                    )}
                  </div>
                  {approvedRequests.map(entry => renderWaitingListCard(entry))}
                </>
              )}

              {(pendingRequests.length > 0 || approvedRequests.length > 0 || cancelledRequests.length > 0) && (
                <>
                  <div className="flex justify-between items-center mb-4 mt-10">
                    <h2 className="text-xl font-semibold">Active waiting list requests ({pendingRequests.length})</h2>
                    {pendingRequests.length > 0 && (
                      <select value={sortOption} onChange={handleSortChange} className="border border-[#DEDEDE] rounded px-2 py-1">
                        <option value="Newest">Newest</option>
                        <option value="Oldest">Oldest</option>
                        <option value="Alphabetically">Alphabetically</option>
                      </select>
                    )}
                  </div>
                  {pendingRequests.length === 0 && <p>No pending waiting list requests found.</p>}
                  {pendingRequests.map(entry => renderWaitingListCard(entry))}
                </>
              )}

              {cancelledRequests.length > 0 && (
                <>
                  <div className="flex justify-between items-center mb-4 mt-10">
                    <h2 className="text-xl font-semibold">Cancelled waiting list requests ({cancelledRequests.length})</h2>
                    {cancelledRequests.length > 0 && (
                      <select value={sortOption} onChange={handleSortChange} className="border border-[#DEDEDE] rounded px-2 py-1">
                        <option value="Newest">Newest</option>
                        <option value="Oldest">Oldest</option>
                        <option value="Alphabetically">Alphabetically</option>
                      </select>
                    )}
                  </div>
                  {cancelledRequests.map(entry => renderWaitingListCard(entry))}
                </>
              )}

              {noPendingOrApprovedRequests && !isManagerOrOwner && (
                <div className="w-full bg-[#0D1B2A] rounded-lg flex">
                  <div className="w-1/3">
                    <img src={personBrowsingImg} alt="Person Browsing" className="h-full w-full object-cover rounded-l-lg" />
                  </div>
                  <div className="w-2/3 text-white p-6 flex flex-col justify-center items-center text-center">
                    <h2 className="text-2xl font-semibold mb-4">Search for waiting lists to join</h2>
                    <p className="mb-4">From restaurants and nail salons, to football teams and youth groups, find waiting lists in your area to join.</p>
                    <Link to="/search-organizations" className="text-blue-500">
                      <button className="bg-white border border-blue-500 text-blue-500 mt-6 px-4 py-2 rounded hover:bg-blue-500 hover:text-white hover:border-white transition-colors">
                        Explore Waiting Lists
                      </button>
                    </Link>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="flex justify-between items-center mb-4 mt-10">
                <h2 className="text-xl font-semibold">Archived waiting list requests ({archivedRequests.length})</h2>
                <select value={sortOption} onChange={handleSortChange} className="border border-[#DEDEDE] rounded px-2 py-1">
                  <option value="Newest">Newest</option>
                  <option value="Oldest">Oldest</option>
                  <option value="Alphabetically">Alphabetically</option>
                </select>
              </div>
              {archivedRequests.map(entry => renderWaitingListCard(entry))}
            </>
          )}
          {archivedRequests.length > 0 && (
            <button
              onClick={() => setShowArchived(!showArchived)}
              className="mt-4 px-4 py-2 bg-[#0D1B2A] text-white rounded hover:bg-[#09223c] transition-colors duration-200"
            >
              {showArchived ? "Hide archived requests" : "View archived requests"}
            </button>
          )}
        </div>
      </div>

      {showLeaveModal && selectedEntry && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h2 className="text-2xl font-semibold mb-4">Are you sure you want to leave the list?</h2>
            <div className="border border-gray-300 p-4 rounded mb-4">
              <div className="flex items-center mb-4">
                <img
                  src={selectedEntry.waitingList.organization.logo || orgIcon}
                  alt="Organization Logo"
                  className="w-16 h-16 rounded object-contain mr-4"
                />
                <div>
                  <h3 className="text-md font-semibold">{selectedEntry.waitingList.name}</h3>
                  <p className="text-sm text-gray-600">{selectedEntry.waitingList.description}</p>
                  <p className="text-sm font-semibold mt-1">{selectedEntry.person.name}</p>
                </div>
              </div>
              <div>
                <p className="font-semibold">Requested at:</p>
                <p>{new Date(selectedEntry.createdAt).toLocaleString()}</p>
                <p className="font-semibold">Status:</p>
                <p>{selectedEntry.status.charAt(0).toUpperCase() + selectedEntry.status.slice(1)}</p>
              </div>
            </div>
            <p className="text-red-500 mb-4">If you leave the list you will have to rejoin and this will increase waiting time.</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowLeaveModal(false)}
                className="px-4 py-2 border border-blue-500 text-blue-500 rounded hover:bg-blue-500 hover:text-white transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleRemovePerson}
                className="px-4 py-2 border border-red-500 text-red-500 rounded hover:bg-red-500 hover:text-white transition-colors"
              >
                Leave Waiting List
              </button>
            </div>
          </div>
        </div>
      )}

      {showArchiveModal && selectedEntry && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h2 className="text-2xl font-semibold mb-4">Are you sure you want to archive this request?</h2>
            <div className="border border-gray-300 p-4 rounded mb-4">
              <div className="flex items-center mb-4">
                <img
                  src={selectedEntry.waitingList.organization.logo || orgIcon}
                  alt="Organization Logo"
                  className="w-16 h-16 rounded object-contain mr-4"
                />
                <div>
                  <h3 className="text-md font-semibold">{selectedEntry.waitingList.name}</h3>
                  <p className="text-sm text-gray-600">{selectedEntry.waitingList.description}</p>
                  <p className="text-sm font-semibold mt-1">{selectedEntry.person.name}</p>
                </div>
              </div>
              <div>
                <p className="font-semibold">Requested at:</p>
                <p>{new Date(selectedEntry.createdAt).toLocaleString()}</p>
                <p className="font-semibold">Status:</p>
                <p>{selectedEntry.status.charAt(0).toUpperCase() + selectedEntry.status.slice(1)}</p>
              </div>
            </div>
            <p className="text-black mb-4">Archived requests are visible by clicking the <span className="font-semibold">View archived requests</span> button.</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowArchiveModal(false)}
                className="px-4 py-2 border border-blue-500 text-blue-500 rounded hover:bg-blue-500 hover:text-white transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleArchivePerson}
                className="px-4 py-2 border border-red-500 text-red-500 rounded hover:bg-red-500 hover:text-white transition-colors"
              >
                Archive Request
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
