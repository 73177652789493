import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import AuthContext from "./context/AuthContext";

const AuthRedirect = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();

  if (isAuthenticated) {
    // Redirect to the dashboard or the requested route
    const redirectTo = location.state?.from?.pathname || "/dashboard";
    return <Navigate to={redirectTo} />;
  }

  return children;
};

export default AuthRedirect;
