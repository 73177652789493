import React, { useState, useEffect } from "react";
import axios from "axios";

const UpdateSubscription = ({ showModal, toggleModal, organizationId, currentSubscription, onSubscriptionChange }) => {
  // const [formData, setFormData] = useState({
  //   subscriptionTier: currentSubscription,
  // });
  // const [organization, setOrganization] = useState(null);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if (organizationId) {
      const fetchOrganizationDetails = async () => {
        try {
          // const res = await axios.get(`${apiBaseUrl}/organization/${organizationId}`, {
          //   headers: {
          //     Authorization: `Bearer ${localStorage.getItem("token")}`,
          //   },
          // });
          // setOrganization(res.data.organization);
          // setFormData({ subscriptionTier: res.data.organization.subscriptionTier });
        } catch (err) {
          setError(err.response?.data?.message || "Failed to fetch organization details");
        }
      };
      fetchOrganizationDetails();
    }
  }, [apiBaseUrl, organizationId]);

  const handleSelectSubscription = async (tier) => {
    try {
      if (tier === "Club/Society/Charity") {
        await axios.post(`${apiBaseUrl}/organization/request-club-society`, {
          organizationId,
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setMessage("Request sent for Club/Society/Charity tier. You will be notified once approved.");
      } else {
        await axios.put(`${apiBaseUrl}/organization/update-subscription`, { subscriptionTier: tier }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setMessage("Subscription tier updated successfully.");
        onSubscriptionChange(tier);
      }
      toggleModal();
    } catch (err) {
      setError(err.response?.data?.message || "Failed to update subscription tier");
    }
  };

  return (
    <>
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-6xl relative">
            <button
              type="button"
              onClick={toggleModal}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 font-semibold"
            >
              Close
            </button>
            <h3 className="text-xl font-semibold text-center mb-4">Subscription Comparison</h3>
            {error && <p className="text-red-500 text-center mb-4">{error}</p>}
            {message && <p className="text-green-500 text-center mb-4">{message}</p>}
            <table className="w-full border-collapse">
              <thead className="bg-gray-800 text-white font-semibold">
                <tr>
                  <th className="border p-2">Feature</th>
                  <th className="border p-2">Free</th>
                  <th className="border p-2">Basic</th>
                  <th className="border p-2">Non-Profit</th>
                  <th className="border p-2">Pro</th>
                  <th className="border p-2">Premium</th>
                  <th className="border p-2">Enterprise</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border p-2 bg-gray-100 font-semibold">Waiting Lists</td>
                  <td className="border p-2">1</td>
                  <td className="border p-2">5</td>
                  <td className="border p-2">10</td>
                  <td className="border p-2">10</td>
                  <td className="border p-2">Unlimited</td>
                  <td className="border p-2">Custom</td>
                </tr>
                <tr>
                  <td className="border p-2 bg-gray-100 font-semibold">People on Lists</td>
                  <td className="border p-2">10</td>
                  <td className="border p-2">Unlimited</td>
                  <td className="border p-2">Unlimited</td>
                  <td className="border p-2">Unlimited</td>
                  <td className="border p-2">Unlimited</td>
                  <td className="border p-2">Unlimited</td>
                </tr>
                <tr>
                  <td className="border p-2 bg-gray-100 font-semibold">Email Notifications</td>
                  <td className="border p-2">Yes</td>
                  <td className="border p-2">Yes</td>
                  <td className="border p-2">Yes</td>
                  <td className="border p-2">Yes</td>
                  <td className="border p-2">Yes</td>
                  <td className="border p-2">Yes</td>
                </tr>
                <tr>
                  <td className="border p-2 bg-gray-100 font-semibold">SMS Notifications</td>
                  <td className="border p-2">No</td>
                  <td className="border p-2">No</td>
                  <td className="border p-2">No</td>
                  <td className="border p-2">Up to 100 SMS</td>
                  <td className="border p-2">Up to 500 SMS</td>
                  <td className="border p-2">Up to 1000 SMS</td>
                </tr>
                <tr>
                  <td className="border p-2 bg-gray-100 font-semibold">Priority Support</td>
                  <td className="border p-2">No</td>
                  <td className="border p-2">No</td>
                  <td className="border p-2">No</td>
                  <td className="border p-2">No</td>
                  <td className="border p-2">Yes</td>
                  <td className="border p-2">Yes</td>
                </tr>
                <tr>
                  <td className="border p-2 bg-gray-100 font-semibold">Price</td>
                  <td className="border p-2">£0</td>
                  <td className="border p-2">£7</td>
                  <td className="border p-2">£10</td>
                  <td className="border p-2">£20</td>
                  <td className="border p-2">£35</td>
                  <td className="border p-2">Custom Pricing</td>
                </tr>
                <tr>
                  <td className="border p-2 bg-gray-100 font-semibold">Select</td>
                  <td className="border p-2">
                    <button
                      onClick={() => handleSelectSubscription("Free")}
                      className="px-4 py-2 border border-blue-500 text-blue-500 rounded hover:bg-blue-500 hover:text-white transition-colors"
                    >
                      Select Free
                    </button>
                  </td>
                  <td className="border p-2">
                    <button
                      onClick={() => handleSelectSubscription("Basic")}
                      className="px-4 py-2 border border-blue-500 text-blue-500 rounded hover:bg-blue-500 hover:text-white transition-colors"
                    >
                      Select Basic
                    </button>
                  </td>
                  <td className="border p-2">
                    <button
                      onClick={() => handleSelectSubscription("Club/Society/Charity")}
                      className="px-4 py-2 border border-blue-500 text-blue-500 rounded hover:bg-blue-500 hover:text-white transition-colors"
                    >
                      Request Non-Profit
                    </button>
                  </td>
                  <td className="border p-2">
                    <button
                      onClick={() => handleSelectSubscription("Pro")}
                      className="px-4 py-2 border border-blue-500 text-blue-500 rounded hover:bg-blue-500 hover:text-white transition-colors"
                    >
                      Select Pro
                    </button>
                  </td>
                  <td className="border p-2">
                    <button
                      onClick={() => handleSelectSubscription("Premium")}
                      className="px-4 py-2 border border-blue-500 text-blue-500 rounded hover:bg-blue-500 hover:text-white transition-colors"
                    >
                      Select Premium
                    </button>
                  </td>
                  <td className="border p-2">
                    <button
                      onClick={() => handleSelectSubscription("Enterprise")}
                      className="px-4 py-2 border border-blue-500 text-blue-500 rounded hover:bg-blue-500 hover:text-white transition-colors"
                    >
                      Select Enterprise
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateSubscription;
