import React, { useState, useEffect, useContext, useCallback } from "react";
import axios from "axios";
import AuthContext from "../context/AuthContext";

const ManageManagers = ({ organizationId }) => {
  const { user } = useContext(AuthContext);
  const [userId, setUserId] = useState("");
  const [role, setRole] = useState("manager");
  const [managers, setManagers] = useState([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchManagers = useCallback(async () => {
    try {
      const res = await axios.get(
        `${apiBaseUrl}/organization/${organizationId}/managers`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setManagers(res.data.managers);
    } catch (err) {
      setError(err.response?.data?.message || "Failed to fetch managers");
    }
  }, [apiBaseUrl, organizationId]);

  useEffect(() => {
    fetchManagers();
  }, [fetchManagers]);

  const handleAddManager = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");

    // Prevent adding the current user
    if (userId === user.email) {
      setError("You cannot add yourself as a manager.");
      return;
    }

    try {
      const res = await axios.post(
        `${apiBaseUrl}/organization/add-manager`,
        { organizationId, userId, role },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setMessage(res.data.message);
      fetchManagers(); // Refresh the managers list
      setUserId("");
      setRole("manager");
    } catch (error) {
      setError(error.response?.data?.message || "Failed to add manager");
    }
  };

  const handleRemoveManager = async (managerId) => {
    setError("");
    setMessage("");

    try {
      await axios.delete(
        `${apiBaseUrl}/organization/${organizationId}/remove-manager/${managerId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      fetchManagers(); // Refresh the managers list
    } catch (error) {
      setError(error.response?.data?.message || "Failed to remove manager");
    }
  };

  return (
    <div className="bg-white">
      <h2 className="text-xl font-semibold mb-4">Organization Managers</h2>
      <form onSubmit={handleAddManager} className="space-y-4">
        <div className="flex flex-col md:flex-row md:items-center">
          <label className="w-full md:w-1/4 text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            placeholder="Email"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            required
            className="mt-1 block w-full md:w-3/4 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="flex flex-col md:flex-row md:items-center">
          <label className="w-full md:w-1/4 text-sm font-medium text-gray-700">Role</label>
          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            required
            className="mt-1 block w-full md:w-3/4 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="manager">Manager</option>
            <option value="owner">Owner</option>
          </select>
        </div>
        <button
          type="submit"
          className="w-full md:w-auto px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Add
        </button>
      </form>
      {message && <p className="text-green-500 mt-4">{message}</p>}
      {error && <p className="text-red-500 mt-4">{error}</p>}
      <div className="mt-8">
        {managers && managers.length > 0 ? (
          <div className="space-y-4">
            {managers.map((manager) => (
              <div key={manager._id} className="flex justify-between items-center border p-4 rounded">
                <div className="flex flex-col">
                  <span className="font-semibold">{manager.email}</span>
                  <span className={`mt-2 px-2 py-1 rounded text-sm font-semibold ${manager.role === "owner" ? "bg-blue-100 text-blue-800" : "bg-green-100 text-green-800"}`}>
                    {manager.role.charAt(0).toUpperCase() + manager.role.slice(1)}
                  </span>
                </div>
                {user && user._id && manager._id !== user._id ? (
                  <button
                    onClick={() => handleRemoveManager(manager._id)}
                    className="text-red-500 border border-red-500 rounded px-4 py-1 hover:bg-red-500 hover:text-white transition-colors"
                  >
                    Remove
                  </button>
                ) : null}
              </div>
            ))}
          </div>
        ) : (
          <p>No managers found.</p>
        )}
      </div>
    </div>
  );
};

export default ManageManagers;
