import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const ProtectedRoute = () => {
  const { isAuthenticated, user } = React.useContext(AuthContext);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (user === null) {
    return null; // Or some kind of loading indicator
  }

  if (!user.isVerified || !user.phoneVerified) {
    return <Navigate to="/verify-email-message" />;
  }
  return <Outlet />;
};

export default ProtectedRoute;
