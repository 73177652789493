// src/routes/routes.js
import React from "react";
import { Route, Routes, Navigate, useLocation, useMatch } from "react-router-dom";
import Navbar from "./components/Navbar";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import Dashboard from "./components/Dashboard/Dashboard";
import NotFound from "./components/NotFound";
import ProtectedRoute from "./components/ProtectedRoute";
import WaitingListDetails from "./components/WaitingListDetails";
import OrganizationWaitingLists from "./components/OrganizationWaitingLists";
import AddPersonPage from "./components/AddPersonPage";
import ViewWaitingList from "./components/ViewWaitingList";
import ManagePeople from "./components/ManagePeople";
import AccountSettings from "./components/AccountSettings";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import OrganizationSearch from "./components/OrganizationSearch";
import CreateOrganization from "./components/CreateOrganization";
import CreateWaitingList from "./components/CreateWaitingList";
import AddWaitingList from "./components/AddWaitingList";
import ManageOrganization from "./components/ManageOrganization";
import EmailVerification from "./components/EmailVerification";
import VerifyEmailMessage from "./components/VerifyEmailMessage";
import OrganizationVanity from "./components/OrganizationVanity";
import UpdateSubscription from "./components/UpdateSubscription";
import AuthRedirect from "./AuthRedirect";
import ConfigureWaitingList from "./components/ConfigureWaitingList"; // Add this import

const RoutesComponent = () => {
  const location = useLocation();

  const noNavbarPaths = [
    "/login",
    "/register",
    "/forgot-password",
    "/verify-email",
    "/verify-email-message"
  ];

  const matchResetPassword = useMatch("/reset-password/:token");

  const showNavbar = !noNavbarPaths.includes(location.pathname) && !matchResetPassword;

  return (
    <>
      {showNavbar && <Navbar />}
      <Routes>
        <Route 
          path="/login" 
          element={
            <AuthRedirect>
              <Login />
            </AuthRedirect>
          } 
        />
        <Route 
          path="/register" 
          element={
            <AuthRedirect>
              <Register />
            </AuthRedirect>
          } 
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/verify-email-message" element={<VerifyEmailMessage />} />
        <Route path="/verify-email" element={<EmailVerification />} />
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/add-person" element={<AddPersonPage />} />
          <Route path="/view-waiting-list/:waitingListId" element={<ViewWaitingList />} />
          <Route path="/manage-people" element={<ManagePeople />} />
          <Route path="/account-settings" element={<AccountSettings />} />
          <Route path="/search-organizations" element={<OrganizationSearch />} />
          <Route path="/create-organization" element={<CreateOrganization />} />
          <Route path="/create-waiting-list" element={<CreateWaitingList />} />
          <Route path="/edit-waiting-list/:waitingListId" element={<ConfigureWaitingList isEditMode={true} />} />

          <Route path="/add-waiting-list" element={<AddWaitingList />} />
          <Route path="/configure-waiting-list/:waitingListId" element={<ConfigureWaitingList />} /> {/* Add this line */}
          <Route path="/manage-organization" element={<ManageOrganization />} />
          <Route path="/update-subscription" element={<UpdateSubscription />} />
        </Route>
        <Route path="/:vanityUrl" element={<OrganizationVanity />} /> {/* Handle vanity URLs */}
        <Route path="/organization/:organizationId" element={<OrganizationWaitingLists />} />
        <Route path="/waiting-list/:id" element={<WaitingListDetails />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default RoutesComponent;
