import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import defaultBackground from '../assets/goqueueback.jpg';
import defaultLogo from '../assets/orgicon.jpg';
import AuthContext from "../context/AuthContext"; // Import AuthContext
import JoinWaitingListModal from "./JoinWaitingListModal"; // Import the modal

const OrganizationWaitingLists = () => {
  const { organizationId } = useParams();
  const [organization, setOrganization] = useState(null);
  const [waitingLists, setWaitingLists] = useState([]);
  const [error, setError] = useState("");
  const { isAuthenticated } = useContext(AuthContext); // Get authentication status from context
  const [showJoinModal, setShowJoinModal] = useState(false);
  const [selectedWaitingListId, setSelectedWaitingListId] = useState(null);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  const handleJoinClick = (waitingListId) => {
    if (isAuthenticated) {
      navigate(`/waiting-list/${waitingListId}/join`);
    } else {
      setSelectedWaitingListId(waitingListId);
      setShowJoinModal(true);
    }
  };

  useEffect(() => {
    const fetchOrganizationAndWaitingLists = async () => {
      try {
        const orgRes = await axios.get(
          `${apiBaseUrl}/organization/${organizationId}`
        );
        setOrganization(orgRes.data.organization);

        const waitingListsRes = await axios.get(
          `${apiBaseUrl}/waiting-list/organization/${organizationId}`
        );
        const lists = waitingListsRes.data.waitingLists;
        setWaitingLists(lists);

        // Automatically navigate if there's only one waiting list
        if (lists.length === 1) {
          navigate(`/waiting-list/${lists[0]._id}`);
        }

      } catch (err) {
        setError(
          err.response?.data?.message || "Failed to fetch data"
        );
      }
    };

    fetchOrganizationAndWaitingLists();
  }, [apiBaseUrl, organizationId, navigate]);

  if (error) return <p>{error}</p>;

  return (
    <div className="bg-[#F8FEFF] min-h-screen p-6 flex flex-col items-center">
      {organization && (
        <div className="w-full max-w-7xl flex flex-col md:flex-row items-center md:items-start bg-white border border-gray-300 rounded-lg shadow-md mb-8">
          <div
            className="w-full md:w-2/3 h-48 md:h-64 bg-cover bg-center rounded-t-lg md:rounded-none md:rounded-l-lg relative"
            style={{ backgroundImage: `url(${organization.backgroundImage || defaultBackground})` }}
          >
            <div className="absolute inset-0 flex items-center justify-center">
              <img
                src={organization.logo || defaultLogo}
                alt={`${organization.name} logo`}
                className="w-24 h-24 rounded-lg bg-white border-2 border-white shadow-md object-contain"
                style={{ top: '50%' }}
              />
            </div>
          </div>
          <div className="text-center md:text-left md:w-1/3 p-6">
            <h1 className="text-2xl font-semibold">{organization.name}</h1>
            <p className="text-gray-600">{organization.description}</p>
            <div className="mt-4">
              {organization.email && (
                <p className="text-sm text-gray-600">
                  Email: <a href={`mailto:${organization.email}`} className="text-indigo-600">{organization.email}</a>
                </p>
              )}
              {organization.contactNumber && (
                <p className="text-sm text-gray-600">
                  Contact Number: {organization.contactNumber}
                </p>
              )}
              {organization.website && (
                <p className="text-sm text-gray-600">
                  Website: <a href={organization.website} target="_blank" rel="noopener noreferrer" className="text-indigo-600">{organization.website}</a>
                </p>
              )}
            </div>
          </div>
        </div>
      )}

      <h2 className="text-2xl font-semibold mb-6">Explore Waiting Lists</h2>
      {waitingLists.length === 0 ? (
        <p>No waiting lists found.</p>
      ) : (
        <div className="w-full max-w-7xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {waitingLists.map((list) => (
            <div 
              key={list._id} 
              className="bg-white border border-gray-300 rounded-lg shadow-md flex flex-col cursor-pointer"
            >
              <div
                className="w-full h-32 bg-cover bg-center rounded-t-lg relative"
                style={{ backgroundImage: `url(${list.bannerImageUrl || organization.backgroundImage || defaultBackground})` }}
              >
                <div className="absolute inset-0 flex items-center justify-center">
                  <img
                    src={list.iconUrl || organization.logo || defaultLogo}
                    alt={`${list.name} icon`}
                    className="w-24 h-24 rounded-lg bg-white border-2 border-white shadow-md object-contain"
                    style={{ top: '50%' }}
                  />
                </div>
              </div>
              <div className="p-6 text-center flex flex-col flex-grow">
                <h3 className="text-lg font-semibold">{list.name}</h3>
                <p className="text-sm text-gray-600">{list.description}</p>
                <button
                  onClick={() => handleJoinClick(list._id)}
                  className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700"
                >
                  Join Waiting List
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* New Modal for Non-Logged-In Users */}
      {showJoinModal && (
        <JoinWaitingListModal
          waitingListId={selectedWaitingListId}
          onClose={() => setShowJoinModal(false)}
        />
      )}
    </div>
  );
};

export default OrganizationWaitingLists;
