import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const countryCodes = [
  { code: "+44", name: "UK" },
  { code: "+1", name: "US" },
  { code: "+91", name: "India" },
  // Add more country codes as needed
];

const AccountSettings = () => {
  const { user, setUser, sendPhoneVerificationCode } = useContext(AuthContext);
  const [name, setName] = useState(user?.name || "");
  const [email, setEmail] = useState(user?.email || "");
  const [countryCode, setCountryCode] = useState("+44");
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber ? user.phoneNumber.replace(countryCode, "") : "");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(""); // New state for confirm password
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if (user?.phoneNumber) {
      const countryCodeMatch = countryCodes.find((code) =>
        user.phoneNumber.startsWith(code.code)
      );
      if (countryCodeMatch) {
        setCountryCode(countryCodeMatch.code);
        setPhoneNumber(
          user.phoneNumber.replace(countryCodeMatch.code, "")
        );
      }
    }
  }, [user]);

  const formatPhoneNumber = (number) => {
    if (number.startsWith("0")) {
      return number.slice(1);
    }
    return number;
  };

  // Define the validatePassword function here
  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    return (
      password.length >= minLength &&
      [hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar].filter(Boolean)
        .length >= 3
    );
  };

  const handleUpdateDetails = async () => {
    const formattedPhoneNumber = countryCode + formatPhoneNumber(phoneNumber);
    try {
      const res = await axios.put(
        `${apiBaseUrl}/user`,
        { name, email, phoneNumber: formattedPhoneNumber },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
  
      const updatedUser = res.data;
  
      console.log("Updated user received from backend:", updatedUser);
  
      // Update the user state in AuthContext
      setUser(updatedUser);
  
      setMessage("Details updated successfully");
      setError(""); // Clear any previous errors
  
      // Check if the phone number was changed
      if (formattedPhoneNumber !== user.phoneNumber) {
        console.log("Phone number has changed, sending verification code...");
        await sendPhoneVerificationCode({ phoneNumber: formattedPhoneNumber });
      }
  
      console.log("Final check before navigating:");
      console.log("User phoneVerified status:", updatedUser.phoneVerified);
  
      // Navigate to verification page if phone number is not verified
      if (!updatedUser.phoneVerified) {
        navigate("/verify-email-message");
      }
    } catch (error) {
      // Log the entire error response for debugging
      console.error("Error during details update:", error.response || error);
  
      // Display a more specific error message if possible
      const errorMessage =
        error.response?.data?.message || "Failed to update details";
      setError(errorMessage);
      setMessage("");
    }
  };
  

  const handleChangePassword = async () => {
    if (!validatePassword(newPassword)) {
      setError(
        "Password must be at least 8 characters long and include at least three of the following: lowercase letters, uppercase letters, numbers, and symbols."
      );
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      await axios.put(
        `${apiBaseUrl}/user/change-password`,
        { currentPassword, newPassword },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setMessage("Password changed successfully");
      setError("");
    } catch (error) {
      setError(error.response?.data?.message || "Failed to change password");
      setMessage("");
    }
  };

  const handleDeleteAccount = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete your account? This action cannot be undone."
    );
    if (confirmed) {
      try {
        await axios.delete(`${apiBaseUrl}/user`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        localStorage.removeItem("token");
        navigate("/login");
      } catch (error) {
        setError(error.response?.data?.message || "Failed to delete account");
      }
    }
  };

  if (!user) {
    return <p>Loading...</p>;
  }

  return (
    <div className="bg-[#F8FEFF] min-h-screen flex justify-center px-4 py-8">
      <div className="w-full max-w-2xl bg-white rounded-lg shadow-md p-6">
        <h2 className="text-2xl font-semibold mb-6">Account Settings</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {message && <p className="text-green-500 mb-4">{message}</p>}
        <div className="space-y-4">
          <div className="flex flex-col md:flex-row md:items-center">
            <label className="w-full md:w-1/4 text-sm font-medium text-gray-700">
              Name:
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full md:w-3/4 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="flex flex-col md:flex-row md:items-center">
            <label className="w-full md:w-1/4 text-sm font-medium text-gray-700">
              Email:
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 block w-full md:w-3/4 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="flex flex-col md:flex-row md:items-center">
            <label className="w-full md:w-1/4 text-sm font-medium text-gray-700">
              Country Code:
            </label>
            <select
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              className="mt-1 block w-full md:w-3/4 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              {countryCodes.map((code) => (
                <option key={code.code} value={code.code}>
                  {code.name} ({code.code})
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col md:flex-row md:items-center">
            <label className="w-full md:w-1/4 text-sm font-medium text-gray-700">
              Mobile Number:
            </label>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="mt-1 block w-full md:w-3/4 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <button
            onClick={handleUpdateDetails}
            className="w-full md:w-auto px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Update Details
          </button>
        </div>

        <div className="mt-8 space-y-4">
          <h3 className="text-xl font-semibold">Change Password</h3>
          <div className="flex flex-col md:flex-row md:items-center">
            <label className="w-full md:w-1/4 text-sm font-medium text-gray-700">
              Current Password:
            </label>
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className="mt-1 block w-full md:w-3/4 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="flex flex-col md:flex-row md:items-center">
            <label className="w-full md:w-1/4 text-sm font-medium text-gray-700">
              New Password:
            </label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="mt-1 block w-full md:w-3/4 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="flex flex-col md:flex-row md:items-center">
            <label className="w-full md:w-1/4 text-sm font-medium text-gray-700">
              Confirm Password:
            </label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="mt-1 block w-full md:w-3/4 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <button
            onClick={handleChangePassword}
            className="w-full md:w-auto px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Change Password
          </button>
        </div>

        <div className="mt-8">
          <button
            onClick={handleDeleteAccount}
            className="w-full md:w-auto px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Delete Account
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
